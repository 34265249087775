import { Col } from "react-bootstrap";

export const ProjectCard = ({ title, description, imgUrl, url}) => {
  const handleButtonClick = () => {
    window.location.href = url; // Tıklanınca yönlendirilecek URL
  };
  
  return (
    <Col size={12} sm={6} md={4}>
      
      <div className="proj-imgbx min_height_icin parent-container">
        
        <img className="min_height_icin_res" src={imgUrl} />
        <div className="proj-txtx" >
          <h4>{title}</h4>
          <span>{description}</span> <br/> <br/>
          <button className="btn btn-primary btn-primary " id="git_buton" onClick={handleButtonClick}>Projeye Git</button>
        
        </div>
      </div>
      
      
    </Col>
  )
}
