import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import e_okul from "../assets/img/görüntüle.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import YOK from "../assets/img/resim-yok.png";
import e_alarm from "../assets/img/e_alarm.png";
import alarm from "../assets/img/alarm.png";
import guvenlik from "../assets/img/ssler.jpg";
import aternos from "../assets/img/aternos.jpeg";
import mp4_photo from "../assets/img/mp4.jpg";
import messaging_fln from "../assets/img/messaging.png";

import uzayli_gif from "../assets/img/saat2.gif";
import kayan_yazı from "../assets/img/kayan_yazi.gif";
import colorSharp2 from "../assets/img/color-sharp2.png";

import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "Çakma E-Okul",
      description: "Bu masaüstü uygulaması C# Windows Forms kullanılarak yazılmıştır. Sınıf oluşturma, öğrencileri sınıflara kaydetme, sınıfları görüntüleme gibi özellikler mevcuttur.",
      imgUrl: e_okul,
      url: "https://github.com/deno832/programlar/tree/main/student_management"
    },
    {
      title: "Çakma Aternos",
      description: "Bu web sitesi aynı aternos gibi Minecraft sunucusunu kontrol etmeye yarar. Sunucuyu başlatıp durdurma, oyuna komut gönderme, server.properties dosyasını düzenleme gibi özellikleri vardır. Herhangi bir front-end framework kullanılmamıştır.",
      imgUrl: aternos,
      url: "https://github.com/deno832/programlar/tree/main/minecraft_server_site"
    },
      {
        title: "Assembly ile Kayan Yazı",
        description: "Bu projede Arduino Uno üzerindeki Atmega 328p yi Assembly kullanarak programladım ve 16x2 LCD ekran üzerinde bir kayan yazı oluşturdum.",
        imgUrl: kayan_yazı,
        url: "https://github.com/deno832/programlar/tree/main/lcd_kayan_yaz%C4%B1_assembly"
      },
      {
      title: "Elektro Alarm",
      description: "Arduino ve Ark Jeneratör modül kullanarak alarm çaldıktan sonraki 10 saniye içerisinde kalkmassan elektroşok cihazını çalıştıran çalar saat.",
      imgUrl: e_alarm,
      url: "https://github.com/deno832/programlar/tree/main/elektroalarm_v2"
    },
    {
      title: "Yüz Tanımalı Güvenlik",
      description: "Odanın Kapısına Yerleştirilen Bir Kamera ile Odaya Giren Kişilerin Yüzlerini Tanıyıp Veri Tabanına Kaydeden ve Mobil Uygulama Aracılığılıyla Veri Tabanını Görüntüleyen Sistem. Raspberry Pi, Python, Java kullandım.",
      imgUrl: guvenlik,
      url: "https://github.com/deno832/programlar/tree/main/y%C3%BCz_tan%C4%B1mal%C4%B1_g%C3%BCvenlik"
    },
    {
      title: "Alarm",
      description: "Odanın İçerisine Yerleştirilen Bir Hareket Sensörü ve Telefona İndirilen Bir Mobil Uygulama İle Odada Her Hareket Algılandığında Telefondan Son Ses Alarm Sesi Yükselir. Raspberry pi, Hareket Sensörü, Python ve Java Kullanıldı.",
      imgUrl: alarm,
      url: "https://github.com/deno832/programlar/tree/main/y%C3%BCz_tan%C4%B1mal%C4%B1_g%C3%BCvenlik"
    },
    {
      title: "Youtube MP4 İndirici",
      description: "YouTube MP4 indirici web sitesi. React ve FastAPI kullanıldı. Kendi Raspberry Pi 4 kartımda cloudflare tunnels ile host edilip bir subdomainime atandı fakat Raspberry Pi'nin video işlemek için gücü yetmedi. Bu nedenle kaldırıldı.",
      imgUrl: mp4_photo,
      url: "https://github.com/deno832/Youtube_mp4_Downloader/tree/main"
    },
    {
      title: "ASP.NET Mesajlaşma API",
      description: "ASP.NET, Entity Framework ORM ve MsSql kullanarak kullanıcıların kayıt olduğu, giriş yaptığı, mesaj gönderip aldığı bir API yazdım. SQL Relationshipsden yararlandım.",
      imgUrl: messaging_fln,
      url: "https://github.com/deno832/programlar/tree/main/Mesajla%C5%9FmaApiAmaAsp.net/Mesajla%C5%9Fma_Api1"
    },
    {
      title: "WearOS Ben10",
      description: "Play Storede ücretsiz Ben10 saat uygulaması olmadığı için yaptığım Ben10 saat uygulaması. Kotlin kullanıldı. Yakında play store'da ücretsiz olarak yayınlamayı düşünüyorum.",
      imgUrl: uzayli_gif,
      url: "https://github.com/deno832/WearOS_Ben10/tree/main"
    }
  ];

  return (
    <section className="project" id="project">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Bazı Projelerim</h2>
                <p>Bunlar şu ana kadar yaptığım projelerden bazıları. Bunların tamamını kendi başıma yaptım. Daha fazlası için GitHub Hesabımı ziyaret edebilirsiniz</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="section">
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
